<template>
  <div class="col-sm-6 col-md-4 col-lg-3">
    <div class="card card-tight card-header-icon" :class="shadowColor" style="border-radius: 4px;opacity: 1;">
      <div class="card-header" style="padding: 15px 0 0 16px !important;">
        <i v-if="useAnnounceIconInHeader" class="fa fa-solid fa-volume-up" style="font-size: 20px;margin-left: 10px;"></i>
        <div :class="$style.title">{{ title }}</div>
        <a-icon v-if="usePercentage" :class="[$style.statusIcon, $style[color]]" :type="iconType" :theme="iconTheme"/>
      </div>
      <div class="card-body" style="padding: 0 !important;">
        <div :class="[$style.percent, bgColor, this.useCount ? $style.contentExtraMargin : '']">
          <span v-if="usePercentage" :class="$style.intPercent">{{ numPercentage | intPart}}</span>
          <span v-if="usePercentage" :class="$style.fracPercent">.{{ numPercentage | fracPart}}</span>
          <span v-if="useCount" :class="$style.intCount">{{ numCount }}</span>
          <span v-if="usePercentage" :class="$style.tailPercent">{{ tailPercentage }}</span>
          <span v-if="useCount" :class="$style.tailCount">{{ tailCount}}</span>
        </div>
        <div v-if="usePercentage" :class="$style.numText">
          <div>
            <a-icon type="stop" :class="$style.inlineIcon" />
            <span :class="$style.label">違反件数</span>
            <span :class="$style.value">{{ numViolation }}</span>
          </div>
          <div>
            <a-icon type="info-circle" theme="filled" :class="$style.inlineIcon" />
            <span :class="$style.label">規制件数</span>
            <span :class="$style.value">{{ numRegulation }}</span>
          </div>
        </div>
        <div @click="onClickSeeMore" :class="$style.seeMoreButton">もっと見る<a-icon type="right" /></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
      color: '',
      bgColor: '',
      shadowColor: '',
      iconType: 'crown',
      iconTheme: 'outlined',
      tailPercentage: '%',
      tailCount: '回アナウンス',
    }
  },
  props: {
    title: { type: String, default: 'defaultTitle' },
    numPercentage: String,
    numViolation: Number,
    numRegulation: Number,
    numCount: Number,
    useAnnounceIconInHeader: Boolean,
    usePercentage: { type: Boolean, default: false },
    useCount: { type: Boolean, default: false },
  },
  filters: {
    intPart: function(value) {
      return value.split('.')[0]
    },
    fracPart: function(value) {
      return value.split('.')[1]
    },
  },
  watch: {
    numPercentage(value) {
      const float = parseFloat(value)
      if (float >= 95) {
        this.color = 'darkGreen'
        this.bgColor = this.$style.bgDarkGreen
        this.shadowColor = this.$style.shadowDarkGreen
        this.iconType = 'crown'
        this.iconTheme = 'outlined'
      } else if (float >= 90) {
        this.color = 'green'
        this.bgColor = this.$style.bgGreen
        this.shadowColor = this.$style.shadowGreen
        this.iconType = 'check-circle'
        this.iconTheme = 'filled'
      } else if (float >= 80) {
        this.color = 'yellow'
        this.bgColor = this.$style.bgYellow
        this.shadowColor = this.$style.shadowYellow
        this.iconType = 'exclamation-circle'
        this.iconTheme = 'filled'
      } else {
        this.color = 'red'
        this.bgColor = this.$style.bgRed
        this.shadowColor = this.$style.shadowRed
        this.iconType = 'warning'
        this.iconTheme = 'filled'
      }
    },
  },
  methods: {
    onClickSeeMore: function() {
      this.$emit('onClickSeeMore')
    },
  },
  beforeMount() {
    if (this.usePercentage) {
      const float = parseFloat(this.numPercentage)
      if (float >= 95) {
        this.color = 'darkGreen'
        this.bgColor = this.$style.bgDarkGreen
        this.shadowColor = this.$style.shadowDarkGreen
        this.iconType = 'crown'
        this.iconTheme = 'outlined'
      } else if (float >= 90) {
        this.color = 'green'
        this.bgColor = this.$style.bgGreen
        this.shadowColor = this.$style.shadowGreen
        this.iconType = 'check-circle'
        this.iconTheme = 'filled'
      } else if (float >= 80) {
        this.color = 'yellow'
        this.bgColor = this.$style.bgYellow
        this.shadowColor = this.$style.shadowYellow
        this.iconType = 'exclamation-circle'
        this.iconTheme = 'filled'
      } else {
        this.color = 'red'
        this.bgColor = this.$style.bgRed
        this.shadowColor = this.$style.shadowRed
        this.iconType = 'warning'
        this.iconTheme = 'filled'
      }
    } else if (this.useCount) {
      this.color = 'gray'
      this.bgColor = this.$style.bgGray
      this.shadowColor = this.$style.shadowGray
    }
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
