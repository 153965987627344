<template>
  <div class="col-sm-6 col-md-4 col-lg-3">
    <div class="card card-tight card-header-icon" :class="isOrderDesc ? $style.bgBest : $style.bgWorst" style="box-shadow: 0px 2px 1px #0000001A;border-radius: 4px;">
      <div class="card-header" :class="isOrderDesc ? $style.bgBest : $style.bgWorst" style="padding: 15px 0 0 16px !important;">
        <i v-if="useAnnounceIconInHeader" class="fa fa-solid fa-volume-up" style="font-size: 20px;margin-left: 10px;vertical-align:top;"></i>
        <div :class="$style.title" style="display: inline-block;">{{ title }}<br>{{ isOrderDesc ? 'ベスト' : 'ワースト'}}</div>
      </div>
      <div class="card-body" style="padding: 9px 0 0 !important;">
        <ul v-if="this.isDataAvailable" style="list-style: none;padding:0;">
          <li v-for="(t,i) in this.rankData" :key="i" :class="$style.rankLine">
            <router-link :to="{ name: linkTo == 'branch' ? 'branchShow' : linkTo == 'vehicle' ? 'vehicleShow' : linkTo == 'user' ? 'userShow' : '', params: { id: t.id }}">
              <div :class="$style.branchLabelLink" class="text-left">{{ t.name }}</div>
            </router-link>
            <div :class="[$style.branchValue, $style[textColor(t.complianceRate)]]" class="text-right"><span v-if="t.complianceRate >= 0">{{t.complianceRate.toFixed(2)}} %</span></div>
          </li>
        </ul>
        <ul v-if="!this.isDataAvailable" style="list-style: none;padding:0;">
          <li :class="$style.rankLine">
            <div :class="$style.branchLabel" class="text-left"></div>
            <div :class="$style.branchValue" class="text-right"></div>
          </li>
          <li :class="$style.rankLine">
            <div :class="$style.noDataLine">No Data</div>
          </li>
          <li :class="$style.rankLine">
            <div :class="$style.branchLabel" class="text-left"></div>
            <div :class="$style.branchValue" class="text-right"></div>
          </li>
        </ul>
        <div @click="onClickSeeMore" :class="$style.seeMoreButton">もっと見る<a-icon type="right" /></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data: function () {
    return {
      color: '',
      tailPercentage: '%',
      isDataAvailable: Boolean,
    }
  },
  props: {
    title: { type: String, default: 'defaultTitle' },
    isOrderDesc: Boolean,
    rankData: Array,
    useAnnounceIconInHeader: Boolean,
    linkTo: { type: String, default: null },
  },
  filters: {
    intPart: function(value) {
      return value.split('.')[0]
    },
    fracPart: function(value) {
      return value.split('.')[1]
    },
  },
  watch: {
    rankData: function(value) {
      this.isDataAvailable = value && value.length > 0
      if (this.isDataAvailable) {
        for (let i = this.rankData.length; i < 3; i++) {
          this.rankData.push({ name: '', complianceRate: -1.0 })
        }
      }
    },
  },
  methods: {
    onClickSeeMore: function() {
      this.$emit('onClickSeeMore')
    },
    textColor: function(value) {
      if (value >= 95) {
        return 'darkGreen'
      } else if (value >= 90) {
        return 'green'
      } else if (value >= 80) {
        return 'yellow'
      } else {
        return 'red'
      }
    },
  },
  beforeMount: function() {
    this.isDataAvailable = this.rankData && this.rankData.length > 0
    if (this.isDataAvailable) {
      for (let i = this.rankData.length; i < 3; i++) {
        this.rankData.push({ name: '', complianceRate: -1.0 })
      }
    }
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>
