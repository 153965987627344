<template>
  <div v-if="myCompanyPlan !== 6">
    <a-drawer
      title="フィルター"
      placement="right"
      :closable="true"
      :visible="drawerVisible"
      :width="520"
      :wrapClassName="$style.filterDrawer"
      @close="drawerClose"
    >
      <h2>データの取得範囲</h2>
      <a-config-provider :locale="locale">
        <p :class="$style.filterLabel">
          対象期間:<br>
          <a-date-picker :value="dateStart" @change="changeDateStart" /> 〜 <a-date-picker style="margin-right: 20px;" :value="dateEnd" @change="changeDateEnd" />
          <span v-if="!dateRangeValid"><br>対象期間は1年以内に設定してください。</span>
        </p>
      </a-config-provider>
      <a-button v-if="dateRangeValid" :class="$style.filledBtn" @click="dataUpdate" :loading="loading">再読み込み</a-button>
      <p v-if="!loading" :class="$style.description" style="margin: 38px 0 20px;"><span :class="$style.emNum">{{ driveDataTotal }}</span> 件の運行データがみつかりました。</p>
      <hr style="margin-bottom: 30px;">
      <h2>データの表示方法</h2>
      <p :class="$style.filterLabel">
        対象支店: <br>
        <a-select
          show-search
          :value="targetBranchSelected"
          :style="'width: 100%'"
          @change="targetBranchItemChange"
          :filter-option="filterOption"
        >
          <a-select-option
            key="0"
            value="0"
            selected
          >
            全て
          </a-select-option>
          <a-select-option
            v-for="item in targetBranchOptions"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </p>
      <p :class="$style.filterLabel">
        対象グループ: <br>
        <a-select
          show-search
          :value="targetGroupSelected"
          :style="'width: 100%'"
          @change="targetGroupItemChange"
          :filter-option="filterOption"
        >
          <a-select-option
            key="0"
            value="0"
            selected
          >
            指定しない
          </a-select-option>
          <a-select-option
            v-for="item in targetGroupOptions"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </p>
      <p v-if="myCompanyPlan !== 4" :class="$style.filterLabel">
        データの単位: <br>
        <a-radio-group
          :value="target"
        >
          <a-radio-button @click="targetChange('user')" value="user">
            ユーザー
          </a-radio-button>
          <a-radio-button @click="targetChange('vehicle')" value="vehicle">
            車両
          </a-radio-button>
        </a-radio-group>
      </p>
      <p :class="$style.filterLabel">
        閲覧対象: <br>
        <a-select
          show-search
          :value="targetSelected"
          :style="'width: 100%'"
          @change="targetItemChange"
          :filter-option="filterOption"
        >
          <a-select-option
            key="0"
            value="0"
            selected
          >
            全て
          </a-select-option>
          <a-select-option
            v-for="item in targetOptions"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </p>
      <p v-if="myUseAnalyze === 1" :class="$style.filterLabel">
        対象とする道路: <br>
        <a-radio-group
          :value="targetRoad"
          @change="roadChange"
        >
          <a-radio-button value="all">
            全て
          </a-radio-button>
          <a-radio-button value="general">
            幹線道路
          </a-radio-button>
          <a-radio-button value="narrow">
            生活道路
          </a-radio-button>
        </a-radio-group>
        <span v-if="myUseAnnounce === 1" :class="$style.tip"><br>※アナウンスの結果は対象の道路に関わらず全ての結果が出力されます。</span>
      </p>

      <p :class="$style.filterLabel">
        区切り方: <br>
        <a-radio-group
          :value="dateXAxis"
          @change="dateXChange"
        >
          <a-radio-button value="month">月</a-radio-button>
          <a-radio-button value="week">週</a-radio-button>
          <a-radio-button value="day">日</a-radio-button>
        </a-radio-group>
      </p>
    </a-drawer>
    <div :class="$style.headerContainer">
      <h1>遵守状況確認</h1>
      <div :class="$style.topInfoButtonsContainer">
        <a-button @click="drawerShow" :class="$style.topInfoButton"><i class="fa fa-filter" style="margin-right: 4px;"></i>フィルター</a-button>
        <a-button @click="showDetailReport" :class="$style.topInfoButton"><i class="fa fa-file-text-o" style="margin-right: 4px;"></i>集計情報</a-button>
      </div>
    </div>
    <div :class="$style.targetDescription">
      <span :class="$style.label">対象期間:</span>
      <span :class="$style.value">{{ dateStart | momentDate }} 〜 {{ dateEnd | momentDate }}</span>
      <div style="display:inline-block;"><span :class="[$style.value, $style.numValue]">{{ driveDataTotal }}</span><span :class="$style.label">件の運行データが見つかりました</span></div>
    </div>
    <div :class="$style.targetDescription">
      <ul>
        <li v-if="targetGroupSelected === '0'"><span :class="$style.label">対象支店:</span><span :class="$style.value">{{ targetBranchSelected === '0' ? '全て' : targetBranchOptions.find(e => e.id === targetBranchSelected).name }}</span></li>
        <li v-if="targetGroupSelected !== '0'"><span :class="$style.label">対象グループ:</span><span :class="$style.value">{{ targetGroupSelected === '0' ? '指定なし' : targetGroupOptions[targetGroupSelected].name }}</span></li>
        <li><span :class="$style.label">データの単位: </span><span :class="$style.value">{{ target === 'user' ? 'ユーザー' : '車両' }}</span></li>
        <li><span :class="$style.label">閲覧対象:</span><span :class="$style.value">{{ targetSelected === '0' ? '全て' : targetOptions.find(e => e.id === targetSelected).name }}</span></li>
        <li><span :class="$style.label">対象道路:</span><span :class="$style.value">{{ targetRoad === 'all' ? '全て' : targetRoad === 'general' ? '幹線道路' : '生活道路' }}</span></li>
        <li><span :class="$style.label">区切り:</span><span :class="$style.value">{{ dateXAxis === 'month' ? '月' : dateXAxis === 'week' ? '週' : '日' }}</span></li>
      </ul>
    </div>
    <div class="row" style="margin-top:30px;">
      <div v-if="loading" class="text-center col-lg-12" style="margin-top: 50px; margin-bottom: 500px;">
        <section class="card">
          <div class="card-body" style="margin: 250px 0;">
            <a-spin tip="Loading..." />
          </div>
        </section>
      </div>
      <info-card v-if="myUseAnalyze === 1"
        title="遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateTotal[2].toFixed(2)"
        :numViolation="displayComplianceRateTotal[0]"
        :numRegulation="displayComplianceRateTotal[1]"
        @onClickSeeMore="showRate(0, '遵守率')"
      />
      <info-card v-if="myUseAnalyze === 1"
        title="法定速度遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateSpeed[2].toFixed(2)"
        :numViolation="displayComplianceRateSpeed[0]"
        :numRegulation="displayComplianceRateSpeed[1]"
        @onClickSeeMore="showRate(1, '法定速度遵守率')"
      />
      <info-card v-if="myUseAnalyze === 1"
        title="一時停止遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateStop[2].toFixed(2)"
        :numViolation="displayComplianceRateStop[0]"
        :numRegulation="displayComplianceRateStop[1]"
        @onClickSeeMore="showRate(2, '一時停止遵守率')"
      />
      <info-card v-if="myUseAnalyze === 1"
        title="通行禁止遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateOneway[2].toFixed(2)"
        :numViolation="displayComplianceRateOneway[0]"
        :numRegulation="displayComplianceRateOneway[1]"
        @onClickSeeMore="showRate(3, '通行禁止遵守率')"
      />
      <info-card v-if="myUseAnalyze === 1"
        title="踏切停止遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateRailway[2].toFixed(2)"
        :numViolation="displayComplianceRateRailway[0]"
        :numRegulation="displayComplianceRateRailway[1]"
        @onClickSeeMore="showRate(4, '踏切停止遵守率')"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0' && targetBranchSelected === '0' && targetGroupSelected === '0'"
        title="遵守率(支店)"
        :rankData="displayRankingBranch.filter(e => e.regulationNum !== 0).slice(0,3)"
        :isOrderDesc="true"
        linkTo="branch"
        @onClickSeeMore="showRanking(displayRankingBranch, '遵守率(支店): ベスト', 'branch')"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0' && targetBranchSelected === '0' && targetGroupSelected === '0'"
        title="遵守率(支店)"
        :rankData="displayRankingBranch.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        linkTo="branch"
        @onClickSeeMore="showRanking(displayRankingBranch.slice(0).reverse(), '遵守率(支店): ワースト', 'branch')"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="遵守率"
        :rankData="displayRankingTarget.filter(e => e.regulationNum !== 0).slice(0,3)"
        :isOrderDesc="true"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTarget, '遵守率: ベスト', target)"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="遵守率"
        :rankData="displayRankingTarget.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTarget.slice(0).reverse(), '遵守率: ワースト', target)"
      />
      <info-card v-if="myUseAnnounce === 1"
        title="アナウンス遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateAnnounce[2].toFixed(2)"
        :numViolation="displayComplianceRateAnnounce[0]"
        :numRegulation="displayComplianceRateAnnounce[1]"
        :useAnnounceIconInHeader="true"
        @onClickSeeMore="showRate(9, 'アナウンス遵守率', 'アナウンス数')"
      />
      <info-card v-if="myUseAnnounce === 1"
        title="一時停止遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateStopAnnounce[2].toFixed(2)"
        :numViolation="displayComplianceRateStopAnnounce[0]"
        :numRegulation="displayComplianceRateStopAnnounce[1]"
        :useAnnounceIconInHeader="true"
        @onClickSeeMore="showRate(5, 'アナウンス一時停止遵守率', 'アナウンス数')"
      />
      <info-card v-if="myUseAnnounce === 1"
        title="踏切停止遵守率"
        :usePercentage="true"
        :numPercentage="displayComplianceRateRailwayAnnounce[2].toFixed(2)"
        :numViolation="displayComplianceRateRailwayAnnounce[0]"
        :numRegulation="displayComplianceRateRailwayAnnounce[1]"
        :useAnnounceIconInHeader="true"
        @onClickSeeMore="showRate(6, 'アナウンス踏切停止遵守率', 'アナウンス数')"
      />
      <info-card v-if="myUseAnnounce === 1"
        title="ゾーン30速度超過"
        :useCount="true"
        :numCount="displayZone30SpeedAnnounce"
        :useAnnounceIconInHeader="true"
        @onClickSeeMore="showCount(7, '速度超過', 'アナウンス数')"
      />
      <info-card v-if="myUseAnnounce === 1"
        title="速度超過"
        :useCount="true"
        :numCount="displaySpeedAnnounce"
        :useAnnounceIconInHeader="true"
        @onClickSeeMore="showCount(8, '速度超過', 'アナウンス数')"
      />
      <rank-card v-if="targetSelected === '0' && targetBranchSelected === '0' && targetGroupSelected === '0' && myUseAnnounce === 1"
        title="遵守率(支店)"
        :rankData="displayRankingBranchAnnounce.filter(e => e.regulationNum !== 0).slice(0,3)"
        :isOrderDesc="true"
        :useAnnounceIconInHeader="true"
        linkTo="branch"
        @onClickSeeMore="showRanking(displayRankingBranchAnnounce, 'アナウンス遵守率(支店): ベスト', 'branch')"
      />
      <rank-card v-if="targetSelected === '0' && targetBranchSelected === '0' && targetGroupSelected === '0' && myUseAnnounce === 1"
        title="遵守率(支店)"
        :rankData="displayRankingBranchAnnounce.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0,3)"
        :isOrderDesc="false"
        :useAnnounceIconInHeader="true"
        linkTo="branch"
        @onClickSeeMore="showRanking(displayRankingBranchAnnounce.slice(0).reverse(), 'アナウンス遵守率(支店): ワースト', 'branch')"
      />
      <rank-card v-if="targetSelected === '0' && myUseAnnounce === 1"
        title="遵守率"
        :rankData="displayRankingTargetAnnounce.filter(e => e.regulationNum !== 0).slice(0,3)"
        :isOrderDesc="true"
        :useAnnounceIconInHeader="true"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetAnnounce, 'アナウンス遵守率: ベスト', target)"
      />
      <rank-card v-if="targetSelected === '0' && myUseAnnounce === 1"
        title="遵守率"
        :rankData="displayRankingTargetAnnounce.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :useAnnounceIconInHeader="true"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetAnnounce.slice(0).reverse(), 'アナウンス遵守率: ワースト', target)"
      />

      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="法定速度遵守率"
        :rankData="displayRankingTargetSpeed.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetSpeed.slice(0).reverse(), '遵守率: ワースト', target)"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="一時停止遵守率"
        :rankData="displayRankingTargetStop.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetStop.slice(0).reverse(), '遵守率: ワースト', target)"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="通行禁止遵守率"
        :rankData="displayRankingTargetOneway.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetOneway.slice(0).reverse(), '遵守率: ワースト', target)"
      />
      <rank-card v-if="myUseAnalyze === 1 && targetSelected === '0'"
        title="踏切停止遵守率"
        :rankData="displayRankingTargetRailway.filter(e => e.regulationNum !== 0).slice(0).reverse().slice(0, 3)"
        :isOrderDesc="false"
        :linkTo="target"
        @onClickSeeMore="showRanking(displayRankingTargetRailway.slice(0).reverse(), '遵守率: ワースト', target)"
      />
      <div class="col-sm-6 col-md-4 col-lg-3 only-print" v-if="targetSelected !== '0'"><br /></div>
      <div class="col-sm-6 col-md-4 col-lg-3" v-if="myUseAnalyze === 1">
        <section class="card">
          <div class="card-header">
            <div class="utils__title" style="margin-left: 16px;">
              <strong>違反種類別割合</strong>
            </div>
          </div>
          <div class="card-body">
            <div>
              <vue-c3 :handler="pieViolation" class="height-300"></vue-c3>
            </div>
          </div>
        </section>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-3" v-if="myUseAnalyze === 1">
        <section class="card">
          <div class="card-header">
            <div class="utils__title" style="margin-left: 16px;">
              <strong>速度超過の内訳の割合</strong>
            </div>
          </div>
          <div class="card-body">
            <div>
              <vue-c3 :handler="pieOverSpeed" class="height-300"></vue-c3>
            </div>
          </div>
        </section>
      </div>

      <div class="col-sm-6" v-if="myUseAnalyze === 1">
        <section class="card">
          <div class="card-header" style="margin-left: 16px;">
            <div class="utils__title">
              <strong>遵守率の推移</strong>
            </div>
          </div>
          <div class="card-body" style="padding-top: 0">
            <vue-c3 :handler="lineComplianceRate" class="height-300"></vue-c3>
          </div>
        </section>
      </div>
      <div class="col-sm-6" v-if="myUseAnalyze === 1">
        <section class="card">
          <div class="card-header">
            <div class="utils__title" style="margin-left: 16px;">
              <strong>時間帯別違反件数</strong>
            </div>
          </div>
          <div class="card-body" style="padding-top: 0">
            <vue-c3 :handler="zoomViolation" class="height-300"></vue-c3>
          </div>
        </section>
      </div>
    </div>
    <a-modal
      :title="rankingTitle"
      :visible="visibleRanking"
      :wrapClassName="$style.rankingModal"
      :footer="null"
      @cancel="modalCancelRanking"
    >
      <div>
        <table class="table table-borderless" :class="$style.newTable">
          <thead>
            <tr>
              <th></th>
              <th>違反件数</th>
              <th>規制件数</th>
              <th>遵守率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(t, i) in rankingData.filter(e => e.regulationNum !== 0)" :key="i" :class="$style[`stripe${i % 2}`]">
              <td class="text-left text-nowrap">
                <router-link :to="{ name: rankingLinkTo == 'branch' ? 'branchShow' : rankingLinkTo == 'vehicle' ? 'vehicleShow' : rankingLinkTo == 'user' ? 'userShow' : '', params: { id: t.id }}">
                {{ i + 1 }}: {{ t.name }}
                </router-link>
              </td>
              <td>{{ t.violationNum }}</td>
              <td>{{ t.regulationNum }}</td>
              <td :class="[$style.tableValue,$style[textColor(t.complianceRate)]]">{{ t.complianceRate.toFixed(2)}} %</td>
            </tr>
          </tbody>
        </table>
        <div v-if="rankingData.filter(e => e.regulationNum === 0).length > 0">
        <div :class="$style.rankingDataDesc">運行データ 0 件</div>
          <table class="table table-borderless ranking">
            <tbody>
              <tr v-for="(t, i) in rankingData.filter(e => e.regulationNum === 0)" :key="i">
                <td class="text-nowrap">{{ t.name }}</td>
                <td class="text-right value"><strong> - </strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <a-button :class="$style.closeBtn" @click="modalCancelRanking">
          閉じる
      </a-button>
    </a-modal>

    <a-modal
      :title="rateTitle"
      :visible="visibleRate"
      :wrapClassName="$style.rateModal"
      :footer="null"
      @cancel="modalCancelRate"
    >
      <div>
        <p v-if="displayDateData[0]">{{ displayDateData[0].date | momentYear }} 年</p>
        <table class="table table-borderless" :class="$style.newTable">
          <thead>
            <tr>
              <th>日付</th>
              <th>違反件数</th>
              <th>{{ regulationName }}</th>
              <th>遵守率</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(t, i) in displayDateData" :key="i" :class="$style[`stripe${i % 2}`]">
              <td class="text-nowrap" v-if="dateXAxis == 'month'">
                {{ t.date | momentMonth }} 月
              </td>
              <td class="text-nowrap" v-if="dateXAxis == 'week'">
                {{ t.date | momentWeekStart }} 〜 {{ t.date | momentWeekEnd }}
              </td>
              <td class="text-nowrap" v-if="dateXAxis == 'day'">
                {{ t.date | momentMonth }}月 {{ t.date | momentDay }} 日
              </td>
              <td>{{ t.violationNum }}</td>
              <td>{{ t.regulationNum }}</td>
              <td :class="[$style.tableValue,$style[textColor(t.complianceRate)]]">{{ t.complianceRate.toFixed(2)}} %</td>
            </tr>
          </tbody>
        </table>
        <div v-if="dateXAxis == 'month'">
          <p :class="$style.monthTips" class="mb-0">※ {{ dateStart | momentMonth }} 月は {{ dateStart | momentWeekStart }} からのデータです。</p>
          <p :class="$style.monthTips">※ {{ dateEnd | momentMonth }} 月は {{ dateEnd | momentWeekStart }} までのデータです。</p>
        </div>
      </div>
      <a-button :class="$style.closeBtn" @click="modalCancelRate">
          閉じる
        </a-button>
    </a-modal>

    <a-modal
      :title="rateTitle"
      :visible="visibleCount"
      :wrapClassName="$style.countModal"
      :footer="null"
      @cancel="modalCancelCount"
    >
      <div>
        <p v-if="displayDateData[0]" style="margin-bottom: 2px;">{{ displayDateData[0].date | momentYear }} 年</p>
        <table class="table table-borderless" :class="$style.newTable">
          <thead>
            <tr>
              <th>日付</th>
              <th>{{ regulationName }}</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="(t, i) in displayDateData" :key="i" :class="$style[`stripe${i % 2}`]">
              <td class="text-nowrap" v-if="dateXAxis == 'month'">
                {{ t.date | momentMonth }} 月
              </td>
              <td class="text-nowrap" v-if="dateXAxis == 'week'">
                {{ t.date | momentWeekStart }} 〜 {{ t.date | momentWeekEnd }}
              </td>
              <td class="text-nowrap" v-if="dateXAxis == 'day'">
                {{ t.date | momentMonth }}月 {{ t.date | momentDay }} 日
              </td>
              <td>{{ t.violationNum }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="dateXAxis == 'month'">
          <p :class="$style.monthTips" class="mb-0">※ {{ dateStart | momentMonth }} 月は {{ dateStart | momentWeekStart }} からのデータです。</p>
          <p :class="$style.monthTips">※ {{ dateEnd | momentMonth }} 月は {{ dateEnd | momentWeekStart }} までのデータです。</p>
        </div>
      </div>
      <a-button :class="$style.closeBtn" @click="modalCancelCount">
        閉じる
      </a-button>
    </a-modal>

    <a-modal
      title="集計情報"
      :visible="visibleDetailReport"
      :wrapClassName="$style.statisticsModal"
      :footer="null"
      @cancel="modalCancelDetailReport"
      width="90%"
    >
      <div>
        <p class="text-right no-print" style="margin-right: 20px; margin-top: 20px;"><a-button type="primary" :class="$style.filledBtn" @click="print">印刷する</a-button></p>
        <div :class="$style.statisticsFrame">
          <h3 class="text-center" :class="$style.statisticsTitle">集計情報</h3>
          <div :class="$style.filterInfoArea">
            <div :class="$style.leftInfo">
              <div>
                <span :class="$style.infoLabel">対象支店</span>
                <span :class="$style.infoValue">{{ targetBranchSelected === '0' ? '全て' : targetBranchOptions.find(e => e.id === targetBranchSelected).name }}</span>
              </div>
              <div>
                <span :class="$style.infoLabel">データの単位</span>
                <span :class="$style.infoValue">{{ target === 'user' ? 'ユーザー' : '車両' }}</span>
              </div>
              <div>
                <span :class="$style.infoLabel">閲覧対象</span>
                <span :class="$style.infoValue">{{ targetSelected === '0' ? '全て' : targetOptions.find(e => e.id === targetSelected).name }}</span>
              </div>
            </div>
            <div :class="$style.rightInfo">
              <div>
                <span :class="$style.infoLabel">対象期間</span>
                <span :class="$style.infoValue">{{ dateStart | momentDate }} 〜<br style="display:none;"> {{ dateEnd | momentDate }}</span>
                </div>
              <div>
                <span :class="$style.infoLabel">総運転時間</span>
                <span :class="$style.infoValue">{{ totalDrivingTime | secondToTime }}</span>
              </div>
              <div>
                <span :class="$style.infoLabel">総走行距離</span>
                <span :class="$style.infoValue">{{ (totalDistance / 1000).toFixed(3) }} km</span>
              </div>
            </div>
          </div>
          <h4>違反情報</h4>
          <div :class="$style.percentArea">
            <div :class="$style.percentContainer">
              <span :class="$style.percentLabel">遵守率:</span>
              <span :class="$style.percentValue"> {{ displayComplianceRateTotal[2].toFixed(2) }} %</span>
            </div>
            <div :class="$style.numContainer">
              <i class="fa fa-ban"></i> <span :class="$style.numLabel">違反件数:</span>
              <span :class="$style.numValue">{{ displayComplianceRateTotal[0] }}</span>
              <i class="fa fa-info-circle" style="margin-left:16px;"></i> <span :class="$style.numLabel">規制件数:</span>
              <span :class="$style.numValue">{{ displayComplianceRateTotal[1] }}</span>
            </div>
          </div>
          <a-table :columns="reportViolationsColumns" :data-source="reportViolations" :pagination="false" size="small"
          :rowClassName="(r, i) => i % 2 === 0 ? '' :  $style.stripe">
            <span slot="num" slot-scope="num">{{ num }} 回</span>
            <span slot="numGeneral" slot-scope="numGeneral">{{ numGeneral }} 回</span>
            <span slot="numNarrow" slot-scope="numNarrow">{{ numNarrow }} 回</span>
            <span slot="perHour" slot-scope="perHour">{{ perHour }} 回</span>
            <span slot="perKm" slot-scope="perKm">{{ perKm }} 回</span>
          </a-table>
        </div>
      </div>
      <a-button :class="$style.closeBtn" @click="modalCancelDetailReport">
        閉じる
      </a-button>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import storeVue from '@/store'
import { enumData } from '@/services/enum'
import moment from 'moment'
import VueC3 from 'vue-c3'
import 'c3/c3.min.css'
import jaJa from 'ant-design-vue/es/locale/ja_JP'
import 'moment/locale/ja'
import InfoCard from '@/components/FormComponents/InfoCard'
import RankCard from '@/components/FormComponents/RankCard'

moment.locale('ja')

function convertTypeToViolationName(violationType) {
  if (violationType === 1) return '速度超過(20 未満)'
  else if (violationType === 2) return '速度超過(20 以上 25 未満)'
  else if (violationType === 3) return '速度超過(25 以上 30 未満)'
  else if (violationType === 4) return '速度超過(30 以上 50 未満)'
  else if (violationType === 5) return '速度超過(50 以上)'
  else if (violationType === 6) return '指定場所一時不停止等'
  else if (violationType === 7) return '踏切不停止等'
  else if (violationType === 8 || violationType === 9) return '通行禁止違反'
  else if (violationType === 10) return '駐停車違反'
  else if (violationType === 11) return '停車禁止違反'
  else if (violationType === 12) return '駐車禁止違反'
}

function convertTimeToHour(time) {
  return (Number(time.substr(0, 2)) + 9) % 24
}

function caliculateComplianceRate(vNum, rNum) {
  if (rNum === 0) return 100
  else return ((rNum - vNum) / rNum) * 100
}

function colorString(val) {
  if (val >= 95) return { color: 'success', icon: 'fa-trophy' }
  else if (val >= 90) return { color: 'check', icon: 'fa-check-circle-o' }
  else if (val >= 80) return { color: 'warning', icon: 'fa-exclamation-circle' }
  else return { color: 'danger', icon: 'fa-exclamation-triangle' }
}

function hourData() {
  return {
    total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    速度超過: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    '速度超過(20 未満)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    '速度超過(20 以上 25 未満)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    '速度超過(25 以上 30 未満)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    '速度超過(30 以上 50 未満)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    '速度超過(50 以上)': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    指定場所一時不停止等: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    踏切不停止等: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    通行禁止違反: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // 駐停車違反: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // 停車禁止違反: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    // 駐車禁止違反: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }
}

function totalData() {
  return {
    driving_time: 0,
    distance: 0,
    compliance_rate: 0,
    r_total: 0,
    r_t_s: 0, // regulation_temporary_stop
    r_m_s: 0, // regulation_maximum_speed
    r_r_s: 0, // regulation_railroad_stop
    r_o: 0, // regulation_oneway
    r_s_d: 0, // regulation_specified_direction
    r_n_p: 0, // regulation_no_parking
    r_n_s: 0, // regulation_no_stop
    violation_total: 0,
    速度超過: 0,
    '速度超過(20 未満)': 0,
    '速度超過(20 以上 25 未満)': 0,
    '速度超過(25 以上 30 未満)': 0,
    '速度超過(30 以上 50 未満)': 0,
    '速度超過(50 以上)': 0,
    指定場所一時不停止等: 0,
    踏切不停止等: 0,
    通行禁止違反: 0,
    // 駐停車違反: 0,
    // 停車禁止: 0,
    // 駐車禁止違反: 0,
    a_v_t_s: 0, // announced_violation_temporary_stop
    a_v_r_s: 0, // announced_violation_railroad_stop
    a_1: 0, // announce 1-x
    a_2: 0, // announce 2-x
    a_4: 0, // announce 4-x
    a_51: 0, // announce 5-1
    a_52: 0, // announce 5-2
  }
}

function dateData() {
  return {
    r_total: 0,
    r_t_s: 0,
    r_m_s: 0,
    r_r_s: 0,
    r_o: 0,
    r_s_d: 0,
    r_n_p: 0,
    r_n_s: 0,
    violation_total: 0,
    速度超過: 0,
    '速度超過(20 未満)': 0,
    '速度超過(20 以上 25 未満)': 0,
    '速度超過(25 以上 30 未満)': 0,
    '速度超過(30 以上 50 未満)': 0,
    '速度超過(50 以上)': 0,
    指定場所一時不停止等: 0,
    踏切不停止等: 0,
    通行禁止違反: 0,
    // 駐停車違反: 0,
    // 停車禁止違反: 0,
    // 駐車禁止違反: 0,
    a_v_t_s: 0, // announced_violation_temporary_stop
    a_v_r_s: 0, // announced_violation_railroad_stop
    a_1: 0, // announce 1-x
    a_2: 0, // announce 2-x
    a_4: 0, // announce 4-x
    a_51: 0, // announce 5-1
    a_52: 0, // announce 5-2
  }
}

function initialData() {
  // Branch, Vehicle, User について以下の配列を作っていく
  // 1階層: ['info', 'total', 'date', 'hour']
  // info: ['name', 'id']
  // total: ['compliance_rate', 'regulation_total', 'violation_total', '速度超過', '速度超過(20 未満)', '速度超過(20 以上 25 未満)', '速度超過(25 以上 30 未満)', '速度超過(30 以上 50 未満)', '速度超過(50 以上)', '指定場所一時不停止等', '踏切不停止等', '通行禁止違反', '駐停車違反', '停車禁止違反']
  // date: [<date>]
  //   -> <date>: [
  //    'regulation_total', 'regulation_temporary_stop', 'regulation_maximum_speed', 'regulation_railroad_stop', 'regulation_oneway', 'regulation_specified_direction', 'regulation_no_parking', 'regulation_no_stop',
  //    'regulation_general_total', 'regulation_general_temporary_stop', 'regulation_general_maximum_speed', 'regulation_general_railroad_stop', 'regulation_general_oneway', 'regulation_general_specified_direction', 'regulation_general_no_parking', 'regulation_general_no_stop',
  //    'regulation_narrow_total', 'regulation_narrow_temporary_stop', 'regulation_narrow_maximum_speed', 'regulation_narrow_railroad_stop', 'regulation_narrow_oneway', 'regulation_narrow_specified_direction', 'regulation_narrow_no_parking', 'regulation_narrow_no_stop',
  //    'violation_total', '速度超過', '速度超過(20 未満)', '速度超過(20 以上 25 未満)', '速度超過(25 以上 30 未満)', '速度超過(30 以上 50 未満)', '速度超過(50 以上)', '指定場所一時不停止等', '踏切不停止等', '通行禁止違反', '駐停車違反', '停車禁止違反'
  //  ]
  // hour: ['total', '速度超過', '指定場所一時不停止等', '踏切不停止等', '通行禁止違反', '駐停車違反', '停車禁止違反'] それぞれ 24 個の数値
  return {
    info: {
      id: null,
      name: '',
    },
    total: {
      all: totalData(),
      general: totalData(),
      narrow: totalData(),
    },
    date: {},
    hour: {
      all: hourData(),
      general: hourData(),
      narrow: hourData(),
    },
  }
}

function initialDateData() {
  return {
    all: dateData(),
    general: dateData(),
    narrow: dateData(),
  }
}

// 1. created: 全要素データを取得
// 2. dateUpdate: 運行データを取得
// 3. driveDataArrange: 運行データを要素データに適用
// 4. presentationDataArrange: 表示する数値を形成
// 4. presentationDataArrangeBranchRanking: 支店のランキングを形成
export default {
  components: {
    VueC3, InfoCard, RankCard,
  },
  data: function () {
    return {
      locale: jaJa,
      loading: true,
      violationTypes: enumData.violationType,
      branches: [],
      users: [],
      vehicles: [],
      driveDataTotal: 0,
      drawerVisible: false,

      dateStart: null,
      dateEnd: null,
      disabledDateStart: null,
      disabledDateEnd: null,
      dateRangeValid: true,
      dateXAxis: 'month',

      target: 'user',
      targetRoad: 'all',
      targetOptions: [],
      targetBranchOptions: [],
      targetGroupOptions: {},
      targetSelected: '0',
      targetBranchSelected: '0',
      targetGroupSelected: '0',

      branchData: [],
      vehicleData: [],
      userData: [],
      totalDistance: 0,
      totalDrivingTime: 0,

      dataCondition: 0,
      dataBranch: 0,
      dataBranchAndVehicle: 0,
      dataAll: 0,

      visibleRanking: false,
      rankingData: [],
      rankingTitle: '',
      rankingLinkTo: '',
      visibleRate: false,
      rateData: [],
      rateTitle: '',
      visibleDetailReport: false,
      regulationName: '規制件数',
      visibleCount: false,

      displayComplianceRateTotal: [0, 0, 100],
      displayComplianceRateSpeed: [0, 0, 100],
      displayComplianceRateStop: [0, 0, 100],
      displayComplianceRateOneway: [0, 0, 100],
      displayComplianceRateRailway: [0, 0, 100],
      displayRankingBranch: [],
      displayRankingTarget: [],
      displayRankingBranchAnnounce: [],
      displayRankingTargetAnnounce: [],
      displayRankingTargetSpeed: [],
      displayRankingTargetStop: [],
      displayRankingTargetOneway: [],
      displayRankingTargetRailway: [],
      displayRanking: [],
      displayComplianceRateTotalDetail: [],
      displayComplianceRateSpeedDetail: [],
      displayComplianceRateStopDetail: [],
      displayComplianceRateOnewayDetail: [],
      displayComplianceRateRailwayDetail: [],
      displaySumViolationData: {},
      displaySumRegulationData: {},
      displayDateData: {},
      // アナウンス
      displayComplianceRateAnnounce: [0, 0, 100],
      displayComplianceRateStopAnnounce: [0, 0, 100],
      displayComplianceRateRailwayAnnounce: [0, 0, 100],
      displayZone30SpeedAnnounce: [0, 0, 100],
      displaySpeedAnnounce: 0,

      pieViolation: new Vue(),
      pieOverSpeed: new Vue(),
      zoomViolation: new Vue(),
      lineComplianceRate: new Vue(),
      colors: {
        no0: '#36A2EB',
        no1: '#FF6384',
        no2: '#FFCE56',
        no3: '#6cbebf',
        no4: 'rgba(153, 102, 255, 1)',
        no5: 'rgba(255, 159, 64, 1)',
        no6: 'rgba(179,181,198,1)',
        no0a: 'rgba(54, 162, 235, 0.2)',
        no1a: 'rgba(255, 99, 132, 0.2)',
        no2a: 'rgba(255, 206, 86, 0.2)',
        no3a: 'rgba(75, 192, 192, 0.2)',
        no4a: 'rgba(153, 102, 255, 0.2)',
        no5a: 'rgba(255, 159, 64, 0.2)',
        no6a: 'rgba(179,181,198,0.2)',
        primary: '#01a8fe',
        def: '#acb7bf',
        success: '#46be8a',
        danger: '#fb434a',
      },

      reportViolationsColumns: [
        { title: '項目', dataIndex: 'name', key: 'name' },
        { title: '回数', dataIndex: 'num', key: 'num', scopedSlots: { customRender: 'num' } },
        { title: '幹線道路', dataIndex: 'numGeneral', key: 'numGeneral', scopedSlots: { customRender: 'numGeneral' } },
        { title: '生活道路', dataIndex: 'numNarrow', key: 'numNarrow', scopedSlots: { customRender: 'numNarrow' } },
        { title: '件数 / 時間(h)', dataIndex: 'perHour', key: 'perHour', scopedSlots: { customRender: 'perHour' } },
        { title: '件数 / 距離(km)', dataIndex: 'perKm', key: 'perKm', scopedSlots: { customRender: 'perKm' } },
      ],
      reportViolations: [
        {
          key: 1,
          name: '速度超過(20 未満)',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 2,
          name: '速度超過(20 以上 25 未満)',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 3,
          name: '速度超過(25 以上 30 未満)',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 4,
          name: '速度超過(30 以上 50 未満)',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 5,
          name: '速度超過(50 以上)',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 6,
          name: '指定場所一時不停止等',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 7,
          name: '踏切不停止等',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
        {
          key: 8,
          name: '通行禁止違反',
          num: 0,
          numGeneral: 0,
          numNarrow: 0,
          perHour: 0,
          perKm: 0,
        },
      ],

      myCompanyPlan: 3,
      myUseAnnounce: 0,
      myUseAnalyze: 0,
      concatVehicleNameAndNo: 1,
    }
  },
  computed: {
    storeCompanyPlan: function() { return storeVue.getters.company_plan },
    storeUseAnnounce: function() { return storeVue.getters.use_announce },
    displayComplianceRateTotalColor() { return colorString(this.displayComplianceRateTotal[2]).color },
    displayComplianceRateSpeedColor() { return colorString(this.displayComplianceRateSpeed[2]).color },
    displayComplianceRateStopColor() { return colorString(this.displayComplianceRateStop[2]).color },
    displayComplianceRateOnewayColor() { return colorString(this.displayComplianceRateOneway[2]).color },
    displayComplianceRateRailwayColor() { return colorString(this.displayComplianceRateRailway[2]).color },
    displayComplianceRateAnnounceColor() { return colorString(this.displayComplianceRateAnnounce[2]).color },
    displayComplianceRateStopAnnounceColor() { return colorString(this.displayComplianceRateStopAnnounce[2]).color },
    displayComplianceRateRailwayAnnounceColor() { return colorString(this.displayComplianceRateRailwayAnnounce[2]).color },
    displayZone30SpeedAnnounceColor() { return colorString(this.displayZone30SpeedAnnounce[2]).color },

    displayComplianceRateTotalIcon() { return colorString(this.displayComplianceRateTotal[2]).icon },
    displayComplianceRateSpeedIcon() { return colorString(this.displayComplianceRateSpeed[2]).icon },
    displayComplianceRateStopIcon() { return colorString(this.displayComplianceRateStop[2]).icon },
    displayComplianceRateOnewayIcon() { return colorString(this.displayComplianceRateOneway[2]).icon },
    displayComplianceRateRailwayIcon() { return colorString(this.displayComplianceRateRailway[2]).icon },
    displayComplianceRateAnnounceIcon() { return colorString(this.displayComplianceRateAnnounce[2]).icon },
    displayComplianceRateStopAnnounceIcon() { return colorString(this.displayComplianceRateStopAnnounce[2]).icon },
    displayComplianceRateRailwayAnnounceIcon() { return colorString(this.displayComplianceRateRailwayAnnounce[2]).icon },
    displayZone30SpeedAnnounceIcon() { return colorString(this.displayZone30SpeedAnnounce[2]).icon },

    pieViolationOptions() {
      return {
        data: {
          columns: [],
          type: 'pie',
        },
        color: {
          pattern: [this.colors.no0, this.colors.no1, this.colors.no2, this.colors.no3, this.colors.no4, this.colors.no5, this.colors.no6],
        },
        legend: {
          position: 'bottom',
        },
      }
    },
    pieOverSpeedOptions() {
      return {
        data: {
          columns: [],
          type: 'pie',
        },
        color: {
          pattern: [this.colors.no0, this.colors.no1, this.colors.no2, this.colors.no3, this.colors.no4, this.colors.no5, this.colors.no6],
        },
        legend: {
          position: 'bottom',
        },
      }
    },
    zoomViolationOptions() {
      return {
        data: {
          columns: [],
          colors: {
            pattern: [this.colors.no0, this.colors.no1, this.colors.no2, this.colors.no3, this.colors.no4, this.colors.no5, this.colors.no6],
          },
        },
        x: {
          outer: !1,
        },
        zoom: {
          enabled: !1,
        },
      }
    },
    lineComplianceRateOptions() {
      const _this = this
      return {
        padding: {
          right: 20,
        },
        data: {
          x: 'x',
          columns: [],
        },
        color: {
          pattern: [this.colors.no0, this.colors.no1, this.colors.no2, this.colors.no3, this.colors.no4, this.colors.no5, this.colors.no6],
        },
        axis: {
          x: {
            outer: !1,
            type: 'timeseries',
            tick: {
              fit: true,
              format: function (date) {
                const dateObj = new Date(date)
                const y = dateObj.getFullYear()
                const m = dateObj.getMonth() + 1
                const d = dateObj.getDate()
                if (_this.dateXAxis === 'month') {
                  return y + '/' + m
                } else if (_this.dateXAxis === 'week') {
                  return m + '/' + d
                } else if (_this.dateXAxis === 'day') {
                  return m + '/' + d
                }
              },
            },
          },
          y: {
            max: 100,
            min: 0,
            tick: {
              outer: !1,
              count: 5,
              values: [0, 80, 90, 95, 100],
            },
          },
        },
        grid: {
          x: {
            show: !1,
          },
          y: {
            show: !0,
          },
        },
      }
    },
  },
  watch: {
    storeCompanyPlan: function(val) { this.myCompanyPlan = val },
    myCompanyPlan: function(val) {
      this.myCompanyPlan = val
      if (val === 4) {
        this.targetChange('vehicle')
      } else {
        this.targetChange('user')
      }
    },
    dataCondition: function(val) {
      if (val === 3) {
        const _this = this
        this.users.forEach((user) => {
          if (user.role <= 1) {
            _this.branches.forEach((branch) => {
              if (_this.branchData[branch.id]) _this.branchData[branch.id]['info']['belong']['users'].push(user.id)
            })
          }
        })
        this.dataAll++
        this.dataUpdate()
      }
    },
    dataBranch: function(val) {
      if (val === 1) {
        const branchesUsersResult = Vue.prototype.$api.send('get', 'branches_users')
        branchesUsersResult.then(response => {
          // console.log('branchesUsers', response)
          response.forEach((bu) => {
            if (this.branchData[bu.branch_id]) this.branchData[bu.branch_id]['info']['belong']['users'].push(bu.user_id)
          })
          this.dataAll++
        })
          .catch(error => {
            this.$notification['error']({
              message: error.status + ': ユーザーの所属支店情報の取得に失敗しました。',
            })
          })
      }
    },
    dataBranchAndVehicle: function(val) {
      if (val === 2) {
        this.vehicles.forEach((v) => {
          if (this.branchData[v.branch_id]) this.branchData[v.branch_id]['info']['belong']['vehicles'].push(v.id)
        })
        this.dataAll++
      }
    },
    dataAll: function(val) {
      if (val === 3) {
        const _this = this
        _this.branches.forEach((branch) => {
          if (_this.branchData[branch.id]) _this.branchData[branch.id]['info']['belong']['users'] = Array.from(new Set(_this.branchData[branch.id]['info']['belong']['users']))
        })
      }
    },
  },
  created() {
    const _this = this
    this.dateEnd = moment()
    this.dateStart = moment().startOf('month')

    const myResult = Vue.prototype.$api.send('get', 'user')
    myResult.then(response => {
      _this.myCompanyPlan = response.company_plan
      _this.myUseAnnounce = response.use_announce
      _this.myUseAnalyze = response.use_analyze
      _this.concatVehicleNameAndNo = response.concat_vehicle_name_and_no
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })

    // 支店、ユーザー、車両の全要素データを取得
    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      this.branches = response
      this.targetBranchOptions = response
      response.forEach((branch) => {
        if (!this.branchData[branch.id]) {
          this.branchData[branch.id] = initialData()
        }
        this.branchData[branch.id]['info']['id'] = branch.id
        this.branchData[branch.id]['info']['name'] = branch.name
        this.branchData[branch.id]['info']['belong'] = {}
        this.branchData[branch.id]['info']['belong']['users'] = []
        this.branchData[branch.id]['info']['belong']['vehicles'] = []
      })
      this.dataCondition++
      this.dataBranch = 1
      this.dataBranchAndVehicle++
      // console.log('branches', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
      })

    const usersResult = Vue.prototype.$api.send('get', 'users_for_dashboard')
    usersResult.then(response => {
      this.users = response
      if (this.myCompanyPlan !== 4) this.targetOptions = response
      response.forEach((user) => {
        if (!this.userData[user.id]) {
          this.userData[user.id] = initialData()
        }
        this.userData[user.id]['info']['id'] = user.id
        this.userData[user.id]['info']['name'] = user.name
      })
      this.dataCondition++
      // console.log('users', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': ユーザーの取得に失敗しました。',
        })
      })

    const vehicleResult = Vue.prototype.$api.send('get', 'vehicles_for_dashboard')
    vehicleResult.then(response => {
      this.vehicles = response.map((item) => {
        if (this.concatVehicleNameAndNo && item.number) item.name = item.name + ',' + item.number
        return item
      })
      if (this.myCompanyPlan === 4) this.targetOptions = response
      response.forEach((vehicle) => {
        if (!this.vehicleData[vehicle.id]) {
          this.vehicleData[vehicle.id] = initialData()
        }
        this.vehicleData[vehicle.id]['info']['id'] = vehicle.id
        this.vehicleData[vehicle.id]['info']['name'] = vehicle.name
      })
      this.dataCondition++
      this.dataBranchAndVehicle++
      // console.log('vehicles', response)
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 車両の取得に失敗しました。',
        })
      })

    const groupResult = Vue.prototype.$api.send('get', 'groups')
    groupResult.then(response => {
      response.forEach((group) => {
        // console.log('group', group)
        const userIds = group.users.map(e => {
          return e.id
        })
        const vehicleIds = group.vehicles.map(e => {
          return e.id
        })
        group.users = userIds
        group.vehicles = vehicleIds
        this.targetGroupOptions[group.id] = group
      })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': グループの取得に失敗しました。',
        })
      })
  },
  filters: {
    momentDate: function (date) { return moment(date).format('YYYY年MM月DD日') },
    momentYear: function (date) { return moment(date).format('YYYY') },
    momentMonth: function (date) { return moment(date).format('MM') },
    momentDay: function (date) { return moment(date).format('DD') },
    momentWeekStart: function (date) { return moment(date).format('MM月DD日') },
    momentWeekEnd: function (date) { return moment(date).clone().add(6, 'd').format('MM月DD日') },
    secondToTime: function(second) {
      let hour = '00'
      if (Math.floor(second / 3600) !== 0) hour = Math.floor(second / 3600)
      return hour + ':' + ('00' + Math.floor(second / 60) % 60).slice(-2) + ':' + ('00' + second % 60).slice(-2)
    },
  },
  methods: {
    print() { window.print() },
    drawerShow() { this.drawerVisible = true },
    drawerClose() { this.drawerVisible = false },
    targetChange(e) {
      this.target = e
      this.targetSelected = '0'
      if (e === 'vehicle') {
        this.targetOptions = this.vehicles
      } else if (e === 'user') {
        this.targetOptions = this.users
      }
      this.presentationDataArrange()
    },
    targetItemChange(val) {
      // console.log('targetItemChange', val)
      this.targetSelected = val
      this.presentationDataArrange()
    },
    targetBranchItemChange(val) {
      // console.log('targetBranchItemChange', val)
      const _this = this
      this.targetBranchSelected = val
      const target = this.target + 's'
      if (val !== '0') {
        this.targetOptions = this[target].map(e => {
          if (_this.branchData[val].info.belong[target].includes(e.id)) return e
        }).filter(Boolean)
      } else {
        this.targetOptions = this[target]
      }
      this.targetGroupSelected = '0'
      this.targetSelected = '0'
      this.presentationDataArrange()
    },
    targetGroupItemChange(val) {
      const _this = this
      this.targetGroupSelected = val
      const target = this.target + 's'
      if (val !== '0') {
        this.targetOptions = this[target].map(e => {
          if (_this.targetGroupOptions[val][target].includes(e.id)) return e
        }).filter(Boolean)
      } else {
        this.targetOptions = this[target]
      }
      this.targetBranchSelected = '0'
      this.targetSelected = '0'
      this.presentationDataArrange()
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    roadChange(e) {
      this.targetRoad = e.target.value
      this.presentationDataArrange()
      this.presentationDataArrangeBranchRanking()
    },
    dateXChange(e) {
      this.dateXAxis = e.target.value
      this.presentationDataArrange()
    },
    convertDateToAxis(date) {
      if (this.dateXAxis === 'month') {
        return date.substr(0, 7) + '-01'
      } else if (this.dateXAxis === 'week') {
        return moment(date, 'YYYY-MM-DD').day(1).format('YYYY-MM-DD')
      } else if (this.dateXAxis === 'day') {
        return date
      }
    },
    changeDateStart(date) {
      this.dateStart = date
      if (this.dateEnd.diff(this.dateStart, 'days') > 365) this.dateRangeValid = false
      else this.dateRangeValid = true
    },
    changeDateEnd(date) {
      this.dateEnd = date
      if (this.dateEnd.diff(this.dateStart, 'days') > 365) this.dateRangeValid = false
      else this.dateRangeValid = true
    },
    showRate(dataIndex, title, regName = '規制件数') {
      this.rateTitle = title
      var displayDateData = []
      for (var date in this.displaySumViolationData) {
        const complianceRate = caliculateComplianceRate(this.displaySumViolationData[date][dataIndex], this.displaySumRegulationData[date][dataIndex])
        displayDateData.push({ date: date, complianceRate: complianceRate, color: colorString(complianceRate).color, violationNum: this.displaySumViolationData[date][dataIndex], regulationNum: this.displaySumRegulationData[date][dataIndex] })
      }
      this.displayDateData = displayDateData
      this.visibleRate = true
      this.regulationName = regName
      // this.rateData = data
    },
    showCount(dataIndex, title, regName = 'アナウンス数') {
      this.rateTitle = title
      var displayDateData = []
      for (var date in this.displaySumViolationData) {
        const complianceRate = caliculateComplianceRate(this.displaySumViolationData[date][dataIndex], this.displaySumRegulationData[date][dataIndex])
        displayDateData.push({ date: date, complianceRate: complianceRate, violationNum: this.displaySumViolationData[date][dataIndex], regulationNum: this.displaySumRegulationData[date][dataIndex] })
      }
      this.displayDateData = displayDateData
      this.visibleCount = true
      this.regulationName = regName
      // this.rateData = data
    },
    modalCancelRate() { this.visibleRate = false },
    modalCancelCount() { this.visibleCount = false },
    showDetailReport() {
      if (this.targetRoad !== 'all') {
        this.targetRoad = 'all'
        this.presentationDataArrange()
        this.presentationDataArrangeBranchRanking()
      }
      this.visibleDetailReport = true
    },
    modalCancelDetailReport() { this.visibleDetailReport = false },
    showRanking(data, title, linkTo) {
      this.visibleRanking = true
      this.rankingData = data
      this.rankingTitle = title
      this.rankingLinkTo = linkTo
    },
    modalCancelRanking() { this.visibleRanking = false },
    // 運行データの取得
    dataUpdate() {
      const _this = this
      const statisticsResult = Vue.prototype.$api.send('get', 'statistics', { dateStart: this.dateStart.format('YYYY-MM-DD'), dateEnd: this.dateEnd.format('YYYY-MM-DD') })
      this.loading = true

      // データの初期化
      this.initDataExcludeInfo()

      // 各運行データを管理する形式に整形していく
      statisticsResult.then(response => {
        // console.log('dataUpdate: statisticsResult', response)
        this.driveDataTotal = 0
        for (var key in response) {
          this.driveDataTotal++
          const dd = response[key]
          // console.log('dataUpdate: drive_data', dd)

          // 日付のデータがない場合は配列を確保
          if (this.branchData[dd.branch_id] && !this.branchData[dd.branch_id]['date'][dd.date]) this.branchData[dd.branch_id]['date'][dd.date] = initialDateData()
          if (this.vehicleData[dd.vehicle_id] && !this.vehicleData[dd.vehicle_id]['date'][dd.date]) this.vehicleData[dd.vehicle_id]['date'][dd.date] = initialDateData()
          if (dd.user_id && this.userData[dd.user_id] && !this.userData[dd.user_id]['date'][dd.date]) this.userData[dd.user_id]['date'][dd.date] = initialDateData()

          // 運行データを整形
          _this.driveDataArrange(dd)
        }

        const targetArray = ['vehicleData', 'userData']
        const roadArray = ['all', 'general', 'narrow']
        targetArray.forEach(t => {
          _this[t].forEach(e => {
            roadArray.forEach(r => {
              e['total'][r].compliance_rate = caliculateComplianceRate(e['total'][r].violation_total, e['total'][r].r_total)
            })
          })
        })

        // filter の値に応じて合算データを埋めていく
        _this.presentationDataArrangeBranchRanking()
        _this.presentationDataArrange()
        _this.loading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 統計情報の取得に失敗しました。',
          })
        })
    },
    // 全要素のinfoデータ以外初期化
    initDataExcludeInfo() {
      const _this = this
      const targetArray = ['branchData', 'vehicleData', 'userData']
      targetArray.forEach(t => {
        _this[t].forEach(targetObj => {
          targetObj['total'] = {
            all: totalData(),
            general: totalData(),
            narrow: totalData(),
          }
          targetObj['date'] = {}
          targetObj['hour'] = {
            all: hourData(),
            general: hourData(),
            narrow: hourData(),
          }
        })
      })
      this.totalDistance = 0
      this.totalDrivingTime = 0
    },
    // driveData から各要素への total, date, hour を埋める
    driveDataArrange(driveData) {
      const _this = this
      const targetArray = ['branchData', 'vehicleData', 'userData']
      const idArray = [driveData.branch_id, driveData.vehicle_id, driveData.user_id]
      const regulationArray = ['r_total', 'r_t_s', 'r_m_s', 'r_r_s', 'r_o', 'r_s_d']
      const regulationGeneralArray = ['r_g_total', 'r_g_t_s', 'r_g_m_s', 'r_g_r_s', 'r_g_o', 'r_g_s_d']
      const regulationNarrowArray = ['r_n_total', 'r_n_t_s', 'r_n_m_s', 'r_n_r_s', 'r_n_o', 'r_n_s_d']

      for (let target = 0; target < targetArray.length; target++) {
        const targetData = targetArray[target]
        if (!idArray[target] || !this[targetData][idArray[target]]) continue

        // 走行距離、運転時間
        this[targetData][idArray[target]]['total']['all']['driving_time'] += driveData['driving_time']
        this[targetData][idArray[target]]['total']['all']['distance'] += driveData['distance']

        // 規制件数: total, date
        for (let i = 0; i < regulationArray.length; i++) {
          this[targetData][idArray[target]]['total']['all'][regulationArray[i]] += driveData[regulationArray[i]]
          this[targetData][idArray[target]]['total']['general'][regulationArray[i]] += driveData[regulationGeneralArray[i]]
          this[targetData][idArray[target]]['total']['narrow'][regulationArray[i]] += driveData[regulationNarrowArray[i]]
          this[targetData][idArray[target]]['date'][driveData.date]['all'][regulationArray[i]] += driveData[regulationArray[i]]
          this[targetData][idArray[target]]['date'][driveData.date]['general'][regulationArray[i]] += driveData[regulationGeneralArray[i]]
          this[targetData][idArray[target]]['date'][driveData.date]['narrow'][regulationArray[i]] += driveData[regulationNarrowArray[i]]
        }

        // 違反情報: total, date, hour
        driveData.violations.data.forEach(function (v) {
          if (v.announced_flag === 1) {
            if (v.type === 6) {
              _this[targetData][idArray[target]]['total']['all']['a_v_t_s']++
              _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_v_t_s']++
            }
            if (v.type === 7) {
              _this[targetData][idArray[target]]['total']['all']['a_v_r_s']++
              _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_v_r_s']++
            }
          }

          if (v.type < 10) {
            for (let count = 0; count < 2; count++) {
              let roadTarget = 'all'
              if (count !== 0) {
                if (v.place === 9) roadTarget = 'narrow'
                else roadTarget = 'general'
              }
              // 違反合計数
              _this[targetData][idArray[target]]['total'][roadTarget]['violation_total']++
              _this[targetData][idArray[target]]['date'][driveData.date][roadTarget]['violation_total']++
              _this[targetData][idArray[target]]['hour'][roadTarget]['total'][convertTimeToHour(v.time)]++
              // それぞれの違反タイプ
              _this[targetData][idArray[target]]['total'][roadTarget][convertTypeToViolationName(v.type)]++
              _this[targetData][idArray[target]]['date'][driveData.date][roadTarget][convertTypeToViolationName(v.type)]++
              _this[targetData][idArray[target]]['hour'][roadTarget][convertTypeToViolationName(v.type)][convertTimeToHour(v.time)]++
              // 速度超過
              if (v.type <= 5) {
                _this[targetData][idArray[target]]['total'][roadTarget]['速度超過']++
                _this[targetData][idArray[target]]['date'][driveData.date][roadTarget]['速度超過']++
                _this[targetData][idArray[target]]['hour'][roadTarget]['速度超過'][convertTimeToHour(v.time)]++
              }
            }
          }
        })

        driveData.announcements.data.forEach(function (a) {
          if (a.code === 1) {
            _this[targetData][idArray[target]]['total']['all']['a_1']++
            _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_1']++
          } else if (a.code === 2) {
            _this[targetData][idArray[target]]['total']['all']['a_2']++
            _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_2']++
          } else if (a.code === 4) {
            _this[targetData][idArray[target]]['total']['all']['a_4']++
            _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_4']++
          } else if (a.code === 5) {
            if (a.code_sub === 1) {
              _this[targetData][idArray[target]]['total']['all']['a_51']++
              _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_51']++
            } else if (a.code_sub === 2) {
              _this[targetData][idArray[target]]['total']['all']['a_52']++
              _this[targetData][idArray[target]]['date'][driveData.date]['all']['a_52']++
            }
          }
        })
      }
    },
    // filter に合わせて表示するデータを成形
    presentationDataArrange() {
      const _this = this

      this.displayComplianceRateTotalDetail = []
      this.displayComplianceRateSpeedDetail = []
      this.displayComplianceRateStopDetail = []
      this.displayComplianceRateOnewayDetail = []
      this.displayComplianceRateRailwayDetail = []
      this.displayRankingTarget = []
      this.displayRankingTargetAnnounce = []
      this.displayRankingTargetSpeed = []
      this.displayRankingTargetStop = []
      this.displayRankingTargetOneway = []
      this.displayRankingTargetRailway = []

      const targetRoad = this.targetRoad

      // filter を適用
      const targetSelected = this.targetSelected
      const targetBranchSelected = this.targetBranchSelected
      const targetGroupSelected = this.targetGroupSelected
      let targetData
      if (targetSelected !== '0') {
        targetData = [_this[this.target + 'Data'][targetSelected]]
      } else if (targetBranchSelected !== '0') {
        const target = this.target + 's'
        targetData = _this[this.target + 'Data'].map(e => {
          if (_this.branchData[Number(targetBranchSelected)].info.belong[target].includes(e.info.id)) return e
        }).filter(Boolean)
      } else if (targetGroupSelected !== '0') {
        const target = this.target + 's'
        targetData = _this[this.target + 'Data'].map(e => {
          if (_this.targetGroupOptions[Number(targetGroupSelected)][target].includes(e.info.id)) return e
        }).filter(Boolean)
      } else {
        targetData = _this[this.target + 'Data']
      }

      // 値
      let regulationTotal = 0
      let regulationMaximumSpeed = 0
      let regulationTemporaryStop = 0
      let regulationOneway = 0
      let regulationRailroadStop = 0
      let violationTotal = 0
      let violationMaximumSpeed = 0
      let violationTemporaryStop = 0
      let violationOneway = 0
      let violationRailroadStop = 0
      const violationSpeed = [0, 0, 0, 0, 0]
      var sumViolationData = {}
      var sumRegulationData = {}
      const violationHour = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

      let announceViolationTemporaryStop = 0
      let announceViolationRailroadStop = 0
      let announceA1 = 0
      let announceA2 = 0
      // let announceA51 = 0
      let announceA52 = 0
      let announceA4 = 0

      const violationArray = ['速度超過(20 未満)', '速度超過(20 以上 25 未満)', '速度超過(25 以上 30 未満)', '速度超過(30 以上 50 未満)', '速度超過(50 以上)', '指定場所一時不停止等', '踏切不停止等', '通行禁止違反']
      for (let i = 0; i < 8; i++) {
        _this.reportViolations[i].num = 0
        _this.reportViolations[i].numGeneral = 0
        _this.reportViolations[i].numNarrow = 0
      }
      _this.totalDrivingTime = 0
      _this.totalDistance = 0

      targetData.forEach(d => {
        // 詳細情報用
        _this.totalDrivingTime += d.total['all']['driving_time']
        _this.totalDistance += d.total['all']['distance']
        for (let i = 0; i < 8; i++) {
          _this.reportViolations[i].num += d.total['all'][violationArray[i]]
          _this.reportViolations[i].numGeneral += d.total['general'][violationArray[i]]
          _this.reportViolations[i].numNarrow += d.total['narrow'][violationArray[i]]
        }

        regulationTotal += d.total[targetRoad]['r_total']
        regulationMaximumSpeed += d.total[targetRoad]['r_m_s']
        regulationTemporaryStop += d.total[targetRoad]['r_t_s']
        regulationOneway += d.total[targetRoad]['r_o']
        regulationOneway += d.total[targetRoad]['r_s_d']
        regulationRailroadStop += d.total[targetRoad]['r_r_s']

        violationTotal += d.total[targetRoad]['violation_total']
        violationMaximumSpeed += d.total[targetRoad]['速度超過']
        violationTemporaryStop += d.total[targetRoad]['指定場所一時不停止等']
        violationOneway += d.total[targetRoad]['通行禁止違反']
        violationRailroadStop += d.total[targetRoad]['踏切不停止等']
        violationSpeed[0] += d.total[targetRoad]['速度超過(20 未満)']
        violationSpeed[1] += d.total[targetRoad]['速度超過(20 以上 25 未満)']
        violationSpeed[2] += d.total[targetRoad]['速度超過(25 以上 30 未満)']
        violationSpeed[3] += d.total[targetRoad]['速度超過(30 以上 50 未満)']
        violationSpeed[4] += d.total[targetRoad]['速度超過(50 以上)']

        announceViolationTemporaryStop += d.total['all']['a_v_t_s']
        announceViolationRailroadStop += d.total['all']['a_v_r_s']
        announceA1 += d.total['all']['a_1']
        announceA2 += d.total['all']['a_2']
        announceA4 += d.total['all']['a_4']
        // announceA51 += d.total['all']['a_51']
        announceA52 += d.total['all']['a_52']

        const complianceRate = caliculateComplianceRate(d.total[targetRoad].violation_total, d.total[targetRoad].r_total)
        _this.displayRankingTarget.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRate, color: colorString(complianceRate).color, regulationNum: d.total[targetRoad].r_total, violationNum: d.total[targetRoad].violation_total })

        const complianceRateAnnounce = caliculateComplianceRate(d.total['all'].a_v_t_s + d.total['all'].a_v_r_s, d.total['all'].a_1 + d.total['all'].a_2)
        _this.displayRankingTargetAnnounce.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateAnnounce, color: colorString(complianceRateAnnounce).color, regulationNum: d.total['all'].a_1 + d.total['all'].a_2, violationNum: d.total['all'].a_v_t_s + d.total['all'].a_v_r_s })

        const complianceRateSpeed = caliculateComplianceRate(d.total[targetRoad]['速度超過'], d.total[targetRoad].r_m_s)
        _this.displayRankingTargetSpeed.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateSpeed, color: colorString(complianceRateSpeed).color, regulationNum: d.total[targetRoad].r_m_s, violationNum: d.total[targetRoad]['速度超過'] })

        const complianceRateStop = caliculateComplianceRate(d.total[targetRoad]['指定場所一時不停止等'], d.total[targetRoad].r_t_s)
        _this.displayRankingTargetStop.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateStop, color: colorString(complianceRateStop).color, regulationNum: d.total[targetRoad].r_t_s, violationNum: d.total[targetRoad]['指定場所一時不停止等'] })

        const complianceRateOneway = caliculateComplianceRate(d.total[targetRoad]['通行禁止違反'], d.total[targetRoad].r_o + d.total[targetRoad].r_s_d)
        _this.displayRankingTargetOneway.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateOneway, color: colorString(complianceRateOneway).color, regulationNum: d.total[targetRoad].r_o + d.total[targetRoad].r_s_d, violationNum: d.total[targetRoad]['通行禁止違反'] })

        const complianceRateRailway = caliculateComplianceRate(d.total[targetRoad]['踏切不停止等'], d.total[targetRoad].r_r_s)
        _this.displayRankingTargetRailway.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateRailway, color: colorString(complianceRateRailway).color, regulationNum: d.total[targetRoad].r_r_s, violationNum: d.total[targetRoad]['踏切不停止等'] })

        // 区切りごとの成形
        for (var date in d.date) {
          if (!sumViolationData[_this.convertDateToAxis(date)]) {
            sumViolationData[_this.convertDateToAxis(date)] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            sumRegulationData[_this.convertDateToAxis(date)] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          }
          sumViolationData[_this.convertDateToAxis(date)][0] += d['date'][date][_this.targetRoad]['violation_total']
          sumRegulationData[_this.convertDateToAxis(date)][0] += d['date'][date][_this.targetRoad]['r_total']
          sumViolationData[_this.convertDateToAxis(date)][1] += d['date'][date][_this.targetRoad]['速度超過']
          sumRegulationData[_this.convertDateToAxis(date)][1] += d['date'][date][_this.targetRoad]['r_m_s']
          sumViolationData[_this.convertDateToAxis(date)][2] += d['date'][date][_this.targetRoad]['指定場所一時不停止等']
          sumRegulationData[_this.convertDateToAxis(date)][2] += d['date'][date][_this.targetRoad]['r_t_s']
          sumViolationData[_this.convertDateToAxis(date)][3] += d['date'][date][_this.targetRoad]['通行禁止違反']
          sumRegulationData[_this.convertDateToAxis(date)][3] += d['date'][date][_this.targetRoad]['r_o']
          sumRegulationData[_this.convertDateToAxis(date)][3] += d['date'][date][_this.targetRoad]['r_s_d']
          sumViolationData[_this.convertDateToAxis(date)][4] += d['date'][date][_this.targetRoad]['踏切不停止等']
          sumRegulationData[_this.convertDateToAxis(date)][4] += d['date'][date][_this.targetRoad]['r_r_s']
          // アナウンス
          sumViolationData[_this.convertDateToAxis(date)][5] += d['date'][date]['all']['a_v_t_s']
          sumRegulationData[_this.convertDateToAxis(date)][5] += d['date'][date]['all']['a_1']
          sumViolationData[_this.convertDateToAxis(date)][6] += d['date'][date]['all']['a_v_r_s']
          sumRegulationData[_this.convertDateToAxis(date)][6] += d['date'][date]['all']['a_2']
          sumViolationData[_this.convertDateToAxis(date)][7] += d['date'][date]['all']['a_52']
          sumRegulationData[_this.convertDateToAxis(date)][7] += d['date'][date]['all']['a_51'] + d.total['all']['a_52']
          sumViolationData[_this.convertDateToAxis(date)][8] += d['date'][date]['all']['a_4']
          sumViolationData[_this.convertDateToAxis(date)][8] += d['date'][date]['all']['a_52']
          sumViolationData[_this.convertDateToAxis(date)][9] += (d['date'][date]['all']['a_v_t_s'] + d['date'][date]['all']['a_v_r_s'])
          sumRegulationData[_this.convertDateToAxis(date)][9] += (d['date'][date]['all']['a_1'] + d['date'][date]['all']['a_2'])
        }

        // 時間ごとの成形
        for (let i = 0; i < 24; i++) {
          violationHour[i] += d['hour'][_this.targetRoad]['total'][i]
        }
      })

      const totalHour = parseFloat(_this.totalDrivingTime) / 3600
      const totalKm = parseFloat(_this.totalDistance) / 1000
      for (let i = 0; i < 8; i++) {
        _this.reportViolations[i].perHour = (_this.reportViolations[i].num / totalHour).toFixed(3)
        _this.reportViolations[i].perKm = (_this.reportViolations[i].num / totalKm).toFixed(3)
      }

      _this.displayRankingTarget.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingTargetAnnounce.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingTargetSpeed.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingTargetStop.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingTargetOneway.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingTargetRailway.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      var obj = Object.entries(sumViolationData)
      obj.sort(function(p1, p2) {
        var p1Key = p1[0]
        var p2Key = p2[0]
        if (p1Key < p2Key) { return -1 }
        if (p1Key > p2Key) { return 1 }
        return 0
      })
      sumViolationData = Object.fromEntries(obj)
      obj = Object.entries(sumRegulationData)
      obj.sort(function(p1, p2) {
        var p1Key = p1[0]
        var p2Key = p2[0]
        if (p1Key < p2Key) { return -1 }
        if (p1Key > p2Key) { return 1 }
        return 0
      })
      sumRegulationData = Object.fromEntries(obj)

      this.displaySumViolationData = sumViolationData
      this.displaySumRegulationData = sumRegulationData
      this.displayComplianceRateTotal = [violationTotal, regulationTotal, caliculateComplianceRate(violationTotal, regulationTotal)]
      this.displayComplianceRateSpeed = [violationMaximumSpeed, regulationMaximumSpeed, caliculateComplianceRate(violationMaximumSpeed, regulationMaximumSpeed)]
      this.displayComplianceRateStop = [violationTemporaryStop, regulationTemporaryStop, caliculateComplianceRate(violationTemporaryStop, regulationTemporaryStop)]
      this.displayComplianceRateOneway = [violationOneway, regulationOneway, caliculateComplianceRate(violationOneway, regulationOneway)]
      this.displayComplianceRateRailway = [violationRailroadStop, regulationRailroadStop, caliculateComplianceRate(violationRailroadStop, regulationRailroadStop)]

      this.displayComplianceRateAnnounce = [announceViolationTemporaryStop + announceViolationRailroadStop, announceA1 + announceA2, caliculateComplianceRate(announceViolationTemporaryStop + announceViolationRailroadStop, announceA1 + announceA2)]
      this.displayComplianceRateStopAnnounce = [announceViolationTemporaryStop, announceA1, caliculateComplianceRate(announceViolationTemporaryStop, announceA1)]
      this.displayComplianceRateRailwayAnnounce = [announceViolationRailroadStop, announceA2, caliculateComplianceRate(announceViolationRailroadStop, announceA2)]
      this.displayZone30SpeedAnnounce = announceA52
      this.displaySpeedAnnounce = announceA4 + announceA52

      // グラフ
      const pieViolationData = [['速度超過', violationMaximumSpeed], ['指定場所一時不停止等', violationTemporaryStop], ['踏切不停止等', violationRailroadStop], ['通行禁止違反', violationOneway]]
      const pieOverSpeedData = [['速度超過(20 未満)', violationSpeed[0]], ['速度超過(20 以上 25 未満)', violationSpeed[1]], ['速度超過(25 以上 30 未満)', violationSpeed[2]], ['速度超過(30 以上 50 未満)', violationSpeed[3]], ['速度超過(50 以上)', violationSpeed[4]]]
      const lineComplianceRateData = [['遵守率'], ['x']]
      for (var date in sumViolationData) {
        lineComplianceRateData[0].push(caliculateComplianceRate(this.displaySumViolationData[date][0], this.displaySumRegulationData[date][0]))
        lineComplianceRateData[1].push(date)
      }
      const zoomViolationData = [['違反件数'].concat(violationHour)]
      this.pieViolation.$emit('init', this.pieViolationOptions)
      this.pieOverSpeed.$emit('init', this.pieOverSpeedOptions)
      this.zoomViolation.$emit('init', this.zoomViolationOptions)
      this.lineComplianceRate.$emit('init', this.lineComplianceRateOptions)
      this.pieViolation.$emit('dispatch', chart => { chart.load({ unload: true, columns: pieViolationData }) })
      this.pieOverSpeed.$emit('dispatch', chart => { chart.load({ unload: true, columns: pieOverSpeedData }) })
      this.lineComplianceRate.$emit('dispatch', chart => { chart.load({ unload: true, columns: lineComplianceRateData }) })
      this.zoomViolation.$emit('dispatch', chart => { chart.load({ unload: true, columns: zoomViolationData }) })
    },
    presentationDataArrangeBranchRanking() {
      const _this = this
      const targetRoad = this.targetRoad
      this.displayRankingBranch = []
      this.displayRankingBranchAnnounce = []

      this.branchData.forEach(d => {
        const complianceRate = caliculateComplianceRate(d.total[targetRoad].violation_total, d.total[targetRoad].r_total)
        _this.displayRankingBranch.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRate, color: colorString(complianceRate).color, regulationNum: d.total[targetRoad].r_total, violationNum: d.total[targetRoad].violation_total })
        const complianceRateAnnounce = caliculateComplianceRate(d.total['all'].a_v_t_s + d.total['all'].a_v_r_s, d.total['all'].a_1 + d.total['all'].a_2)
        _this.displayRankingBranchAnnounce.push({ id: d.info.id, name: d.info.name, complianceRate: complianceRateAnnounce, color: colorString(complianceRateAnnounce).color, regulationNum: d.total['all'].a_1 + d.total['all'].a_2, violationNum: d.total['all'].a_v_t_s + d.total['all'].a_v_r_s })
      })
      _this.displayRankingBranch.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
      _this.displayRankingBranchAnnounce.sort(function(a, b) {
        return b.complianceRate - a.complianceRate
      })
    },
    textColor: function(value) {
      if (value >= 95) {
        return 'darkGreen'
      } else if (value >= 90) {
        return 'green'
      } else if (value >= 80) {
        return 'yellow'
      } else {
        return 'red'
      }
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style>
  .ant-calendar-header .ant-calendar-prev-month-btn::before,
  .ant-calendar-header .ant-calendar-prev-month-btn::after,
  .ant-calendar-header .ant-calendar-prev-century-btn::before,
  .ant-calendar-header .ant-calendar-prev-decade-btn::before,
  .ant-calendar-header .ant-calendar-prev-year-btn::before,
  .ant-calendar-header .ant-calendar-prev-century-btn::after,
  .ant-calendar-header .ant-calendar-prev-decade-btn::after,
  .ant-calendar-header .ant-calendar-prev-year-btn::after,
  .ant-calendar-header .ant-calendar-next-month-btn::before,
  .ant-calendar-header .ant-calendar-next-month-btn::after,
  .ant-calendar-header .ant-calendar-next-century-btn::before,
  .ant-calendar-header .ant-calendar-next-decade-btn::before,
  .ant-calendar-header .ant-calendar-next-year-btn::before,
  .ant-calendar-header .ant-calendar-next-century-btn::after,
  .ant-calendar-header .ant-calendar-next-decade-btn::after,
  .ant-calendar-header .ant-calendar-next-year-btn::after {
    border-color: black !important;
    border-width: 2px 0 0 2px;
  }
  .ant-calendar-header .ant-calendar-prev-month-btn { left: 36px; }
  .ant-calendar-header .ant-calendar-next-month-btn { right: 36px; }
  .card.card-mini .card-header {
    padding-top: 0.75rem;
    padding-bottom: 0.45rem;
    padding-left: 1.6rem;
  }
  .card.card-mini .card-body {
    padding-top: 1.4rem;
    padding-bottom: 1.3rem;
    padding-left: 1.6rem;
  }
  .card.card-mini .card-body .mb0 {
    margin-bottom: 0;
  }
  .card.card-mini .utils__title {
    color: #fff;
  }
  .card-mini.danger .card-header { background: #FF5050; }
  .card-mini.danger .card-body .value { color: #FF5050; }
  .card-mini.warning .card-header { background: #FFB24C; }
  .card-mini.warning .card-body .value { color: #FFA329; }
  .card-mini.check .card-header { background: #185D9A; }
  .card-mini.check .card-body .value { color: #185D9A; }
  .card-mini.success .card-header { background: #0C8CFE; }
  .card-mini.success .card-body .value { color: #0C8CFE; }
  .card.card-mini .card-body {
    padding-top: 1.4rem;
    padding-bottom: 1.3rem;
    padding-left: 1.6rem;
  }
  .card.card-mini .card-body .mb0 {
    margin-bottom: 0;
  }
  .card.card-mini .utils__title {
    color: #fff;
  }

  .card.card-tight .card-header {
    padding-top: 0.75rem;
    padding-bottom: 0.45rem;
    padding-right: 0.3rem;
  }
  .card.card-tight.card-header-icon .card-header {
    padding-left: 0;
  }
  .card.card-tight .card-header .notice-icon {
    margin-left: 0.75rem;
    margin-right: 3px;
    width: 30px;
    padding: 12px;
    color: #fff;
  }
  .card.card-tight .card-body {
    padding-top: 0.4rem;
    padding-bottom: 1.3rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .card.card-tight .card-body .mb0 {
    margin-bottom: 0;
  }
  .card-tight.danger .card-header .notice-icon { background: #FF5050; }
  .card-tight.danger .card-body .value { color: #FF5050; }
  .card.card-tight.danger { border: 1px solid #FF5050; }
  .card-tight.warning .card-header .notice-icon { background: #FFB24C; }
  .card-tight.warning .card-body .value { color: #FFA329; }
  .card.card-tight.warning { border: 1px solid #FFA329; }
  .card-tight.check .card-header .notice-icon { background: #166E4B; }
  .card-tight.check .card-body .value { color: #166E4B; }
  .card.card-tight.check { border: 1px solid #166E4B; }
  .card-tight.success .card-header .notice-icon { background: #20C859; }
  .card-tight.success .card-body .value { color: #20C859; }
  .card.card-tight.success { border: 1px solid #20C859; }

  .table.ranking tr:first-child td { padding-top: 0; }
  .table.ranking td {
    padding-bottom: 0.1rem;
    padding-top: 0.9rem;
  }
  .table.ranking .rank-border-danger { border-bottom: 1px solid #FF5050; }
  .table.ranking .rank-border-danger .value { color: #FF5050; }
  .table.ranking .rank-border-warning { border-bottom: 1px solid #FFA329; }
  .table.ranking .rank-border-warning .value { color: #FFA329; }
  .table.ranking .rank-border-check { border-bottom: 1px solid #166E4B; }
  .table.ranking .rank-border-check .value { color: #166E4B; }
  .table.ranking .rank-border-success { border-bottom: 1px solid #20C859; }
  .table.ranking .rank-border-success .value { color: #20C859; }

  @media (max-width: 540px) {
    .ant-drawer-content-wrapper { width: 80% !important; }
  }
  .only-print { display: none !important; }

  @media print {
    .no-print { display: none; }
    .ant-modal { width: 100%; }
    .ant-modal-header, .ant-modal-footer, .ant-modal-close { display: none; }
    .only-print { display: block !important; }
    .card { margin-bottom: 1rem; }
    .card-boty {
      padding-top: 1rem;
      padding-bottom: 0.4rem;
    }
  }

</style>
