var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-6 col-md-4 col-lg-3" }, [
    _c(
      "div",
      {
        staticClass: "card card-tight card-header-icon",
        class: _vm.isOrderDesc ? _vm.$style.bgBest : _vm.$style.bgWorst,
        staticStyle: {
          "box-shadow": "0px 2px 1px #0000001A",
          "border-radius": "4px"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "card-header",
            class: _vm.isOrderDesc ? _vm.$style.bgBest : _vm.$style.bgWorst,
            staticStyle: { padding: "15px 0 0 16px !important" }
          },
          [
            _vm.useAnnounceIconInHeader
              ? _c("i", {
                  staticClass: "fa fa-solid fa-volume-up",
                  staticStyle: {
                    "font-size": "20px",
                    "margin-left": "10px",
                    "vertical-align": "top"
                  }
                })
              : _vm._e(),
            _c(
              "div",
              {
                class: _vm.$style.title,
                staticStyle: { display: "inline-block" }
              },
              [
                _vm._v(_vm._s(_vm.title)),
                _c("br"),
                _vm._v(_vm._s(_vm.isOrderDesc ? "ベスト" : "ワースト"))
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "card-body",
            staticStyle: { padding: "9px 0 0 !important" }
          },
          [
            this.isDataAvailable
              ? _c(
                  "ul",
                  { staticStyle: { "list-style": "none", padding: "0" } },
                  _vm._l(this.rankData, function(t, i) {
                    return _c(
                      "li",
                      { key: i, class: _vm.$style.rankLine },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name:
                                  _vm.linkTo == "branch"
                                    ? "branchShow"
                                    : _vm.linkTo == "vehicle"
                                    ? "vehicleShow"
                                    : _vm.linkTo == "user"
                                    ? "userShow"
                                    : "",
                                params: { id: t.id }
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-left",
                                class: _vm.$style.branchLabelLink
                              },
                              [_vm._v(_vm._s(t.name))]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-right",
                            class: [
                              _vm.$style.branchValue,
                              _vm.$style[_vm.textColor(t.complianceRate)]
                            ]
                          },
                          [
                            t.complianceRate >= 0
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(t.complianceRate.toFixed(2)) + " %"
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e(),
            !this.isDataAvailable
              ? _c(
                  "ul",
                  { staticStyle: { "list-style": "none", padding: "0" } },
                  [
                    _c("li", { class: _vm.$style.rankLine }, [
                      _c("div", {
                        staticClass: "text-left",
                        class: _vm.$style.branchLabel
                      }),
                      _c("div", {
                        staticClass: "text-right",
                        class: _vm.$style.branchValue
                      })
                    ]),
                    _c("li", { class: _vm.$style.rankLine }, [
                      _c("div", { class: _vm.$style.noDataLine }, [
                        _vm._v("No Data")
                      ])
                    ]),
                    _c("li", { class: _vm.$style.rankLine }, [
                      _c("div", {
                        staticClass: "text-left",
                        class: _vm.$style.branchLabel
                      }),
                      _c("div", {
                        staticClass: "text-right",
                        class: _vm.$style.branchValue
                      })
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: _vm.$style.seeMoreButton,
                on: { click: _vm.onClickSeeMore }
              },
              [
                _vm._v("もっと見る"),
                _c("a-icon", { attrs: { type: "right" } })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }