var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-6 col-md-4 col-lg-3" }, [
    _c(
      "div",
      {
        staticClass: "card card-tight card-header-icon",
        class: _vm.shadowColor,
        staticStyle: { "border-radius": "4px", opacity: "1" }
      },
      [
        _c(
          "div",
          {
            staticClass: "card-header",
            staticStyle: { padding: "15px 0 0 16px !important" }
          },
          [
            _vm.useAnnounceIconInHeader
              ? _c("i", {
                  staticClass: "fa fa-solid fa-volume-up",
                  staticStyle: { "font-size": "20px", "margin-left": "10px" }
                })
              : _vm._e(),
            _c("div", { class: _vm.$style.title }, [_vm._v(_vm._s(_vm.title))]),
            _vm.usePercentage
              ? _c("a-icon", {
                  class: [_vm.$style.statusIcon, _vm.$style[_vm.color]],
                  attrs: { type: _vm.iconType, theme: _vm.iconTheme }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "card-body",
            staticStyle: { padding: "0 !important" }
          },
          [
            _c(
              "div",
              {
                class: [
                  _vm.$style.percent,
                  _vm.bgColor,
                  this.useCount ? _vm.$style.contentExtraMargin : ""
                ]
              },
              [
                _vm.usePercentage
                  ? _c("span", { class: _vm.$style.intPercent }, [
                      _vm._v(_vm._s(_vm._f("intPart")(_vm.numPercentage)))
                    ])
                  : _vm._e(),
                _vm.usePercentage
                  ? _c("span", { class: _vm.$style.fracPercent }, [
                      _vm._v(
                        "." + _vm._s(_vm._f("fracPart")(_vm.numPercentage))
                      )
                    ])
                  : _vm._e(),
                _vm.useCount
                  ? _c("span", { class: _vm.$style.intCount }, [
                      _vm._v(_vm._s(_vm.numCount))
                    ])
                  : _vm._e(),
                _vm.usePercentage
                  ? _c("span", { class: _vm.$style.tailPercent }, [
                      _vm._v(_vm._s(_vm.tailPercentage))
                    ])
                  : _vm._e(),
                _vm.useCount
                  ? _c("span", { class: _vm.$style.tailCount }, [
                      _vm._v(_vm._s(_vm.tailCount))
                    ])
                  : _vm._e()
              ]
            ),
            _vm.usePercentage
              ? _c("div", { class: _vm.$style.numText }, [
                  _c(
                    "div",
                    [
                      _c("a-icon", {
                        class: _vm.$style.inlineIcon,
                        attrs: { type: "stop" }
                      }),
                      _c("span", { class: _vm.$style.label }, [
                        _vm._v("違反件数")
                      ]),
                      _c("span", { class: _vm.$style.value }, [
                        _vm._v(_vm._s(_vm.numViolation))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("a-icon", {
                        class: _vm.$style.inlineIcon,
                        attrs: { type: "info-circle", theme: "filled" }
                      }),
                      _c("span", { class: _vm.$style.label }, [
                        _vm._v("規制件数")
                      ]),
                      _c("span", { class: _vm.$style.value }, [
                        _vm._v(_vm._s(_vm.numRegulation))
                      ])
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              {
                class: _vm.$style.seeMoreButton,
                on: { click: _vm.onClickSeeMore }
              },
              [
                _vm._v("もっと見る"),
                _c("a-icon", { attrs: { type: "right" } })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }